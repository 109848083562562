<template>
	<WhiteBoard v-if="board_created"></WhiteBoard>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import WhiteBoard from "@/components/research/modules/_common/WhiteBoard.vue";
import { collaborationStepsManageMixin } from "@/mixins/collaboration/stepsManageMixin.js";

export default {
	name: "ChooseTopicAltWizardMindmap",

	mixins: [collaborationStepsManageMixin],

	data() {
		return {
			board_created: false,
		};
	},

	components: {
		WhiteBoard,
	},

	created() {
		this.isBoardExists();

		this.$eventBus.$on("wmm-redirect-back", async (payload) => {
			this.redirectBack();
		});
		this.$eventBus.$on("wmm-redirect-next", async (payload) => {
			this.redirectNext();
		});
		this.$eventBus.$on("wmm-finish-work", async (payload) => {
			this.$emit("wmm-process-completed");
		});
	},

	methods: {
		async isBoardExists() {
			let response = await this.$store.dispatch("whiteboard/list", {
				category: this.$defines.SYSTEM_CATEGORY,
				type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
			});

			if (!response || !response.length) {
				// Create the board
				let response = await this.$store.dispatch("whiteboard/create", {
					uuid: uuidv4(),
					category: this.$defines.SYSTEM_CATEGORY,
					type: this.$defines.WHITEBOARD_TYPES.WIZARD_MINDMAP,
				});

				// Reset steps
				this.$eventBus.$emit("set-steps-to-initial", {
					current_step: 1,
					completed_step: 0,
				});
			}

			// Set the flag
			this.board_created = true;
		},
	},

	beforeDestroy() {
		/**
		 * Vue does not destroy listeners until we don't load the page and that's why
		 * whenever any event emit the previously present listeners can also
		 * listen the emitted event and there could be a problem of duplicate
		 * triplicate listeners. So destroy those manually.
		 */

		this.$eventBus.$off("wmm-redirect-back");
		this.$eventBus.$off("wmm-redirect-next");
		this.$eventBus.$off("wmm-finish-work");
	},
};
</script>
