<template>
	<v-dialog :value="dialog" scrollable max-width="500px" persistent>
		<AppDialog
			:action="{
				label: 'app.okay',
				event: 'confirm',
			}"
			@close="$emit('close')"
			@confirm="$emit('confirm')"
		>
			<template v-slot:dialog-content>
				<v-img
					:src="require('@/assets/images/fireworks.png')"
					width="80"
				></v-img>

				<div class="title font-weight-bold oflm-wr mt-8">
					{{ $t("app.congratulations") }}
				</div>

				<div
					class="subtitle-1 mt-2"
					v-html="$t('app.mind_map_finish_confirmation')"
				></div>
			</template>
		</AppDialog>
	</v-dialog>
</template>

<script>
import AppDialog from "@/components/ui/AppDialog.vue";

export default {
	name: "ChooseTopicFinishConfirmationDialog",

	props: {
		dialog: {
			required: true,
			type: Boolean,
		},
	},

	components: {
		AppDialog,
	},
};
</script>
