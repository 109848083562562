import FinishConfirmationDialog from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/_common/FinishConfirmationDialog.vue";

export const chooseTopicAlternativesMixin = {
	data() {
		return {
			dialog: false,
		};
	},

	components: {
		FinishConfirmationDialog,
	},

	methods: {
		openFinishDialog() {
			this.dialog = true;
		},

		proceedToNext() {
			try {
				this.$loader.start();

				this.dialog = false;

				this.$router.push({
					name: "ChooseTopicSummary",
				});
			} catch (error) {
				this.$announce.error(error);
			} finally {
				this.$loader.stop();
			}
		},
	},
};
