<template>
	<div>
		<WizardMindmap @wmm-process-completed="openFinishDialog()"></WizardMindmap>

		<!-- Ask before close mind map  -->
		<FinishConfirmationDialog
			v-model="dialog"
			:dialog="dialog"
			@close="dialog = false"
			@confirm="proceedToNext()"
		></FinishConfirmationDialog>
	</div>
</template>

<script>
import WizardMindmap from "@/components/research/modules/defineQuestion/stages/chooseTopic/alternatives/wizardMindmap/Index";
import { chooseTopicAlternativesMixin } from "@/mixins/chooseTopicAlternativesMixin";

export default {
	name: "ChooseTopicAltWizardMindmapPage",

	mixins: [chooseTopicAlternativesMixin],

	components: {
		WizardMindmap,
	},
};
</script>

<style></style>
